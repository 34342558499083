<template>
  <div>
    <template v-if="$store.state.dashboard.loading">
      <v-row style="padding: 80px 0">
        <v-col cols="15" class="text-center">
          <v-progress-circular :size="70" :width="4" color="gray" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </template>

            <CreateRegistrationModal></CreateRegistrationModal>

 <!-- 
  <v-row class="text-center" v-if="!$store.state.dashboard.loading && vehicles.length">
     <v-col cols="4">
        <LinkButton linkName="app.faq" icon="mdi-help" :title="($vuetify.breakpoint.smAndDown ? '' : 'Have') + ' Questions'"></LinkButton>
      </v-col>
      <v-col cols="4">
        <LinkButton linkName="app.documentsNeeded" icon="mdi-file" :title="($vuetify.breakpoint.smAndDown ? 'Example Docs' : 'Document Examples')"></LinkButton>
      </v-col> 
      <v-col cols="4">
        <CreateRegistrationModal></CreateRegistrationModal>
      </v-col>
    </v-row> -->

<!-- <v-row class="text-center">
      <v-col cols="15">


        <div class="gotham-font mt-12 text-center mainRed--text" :class="[$vuetify.breakpoint.smAndDown ? 'text-h7' : 'text-h6']">**MANY COUNTIES ARE EXPERIENCING LONGER THAN NORMAL DELAYS. County mandated late fees are incurred at 30 DAYS and again at 60 DAYS after date of sale!  To avoid late fees, TTG must have all documents and payment 5 days prior to late fee date.**</div>

        <div class="gotham-font mt-12 text-center yellow1 mainBlack--text" :class="[$vuetify.breakpoint.smAndDown ? 'text-h7' : 'text-h6']">Please check e-mail for updates (check spam folder).</div>

      </v-col>
    </v-row>  -->


    <v-row class="text-center">
      <v-col cols="15">
        <iframe
          src="https://player.vimeo.com/video/543795257"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </v-col>
     
    </v-row> 

    <!-- <v-row class="text-center">
      <v-col cols="2">
        <CreateRegistrationModal></CreateRegistrationModal>
      </v-col>
    </v-row>  -->


    <div v-if="$store.state.dashboard.errorMessage" class="error-msg">Error: {{ $store.state.dashboard.errorMessage }}</div>

    <NoRegistrationsMsg :show-msg="!$store.state.dashboard.loading && !vehicles.length"></NoRegistrationsMsg>

    <template v-if="vehicles.length">
      <div class="mt-15 text-h5">Registrations In Process ({{ inProcessRegistrations.length }})</div>
      <v-list two-line class="mt-4" v-if="inProcessRegistrations">
        <template
          v-for="({
            Status,
            DateCreated,
            VehicleYear,
            VehicleMake,
            VehicleModel,
            RegistrationID,
            RegistrationAmount,
            TotalReceived,
            ClientTrackingNumber,
            LastUpdatedCostBreakdown,
            LastUpdatedPaymentMethod,
            CPDeadline,
            CPRequestSubmittedOn,
            DocApprovedStatus,
            DocReceivedStatus,
            PaymentType,
          },
          idx) in inProcessRegistrations"
        >
          <RegistrationItem
            :key="idx"
            :status="Status"
            :registrationAmount="RegistrationAmount"
            :costBreakdownDate="LastUpdatedCostBreakdown"
            :paymentMethodDate="LastUpdatedPaymentMethod"
            :clientTrackingNumber="ClientTrackingNumber"
            :date-created="DateCreated"
            :regId="RegistrationID"
            :totalReceived="TotalReceived"
            :cpRequestSubmittedOn="CPRequestSubmittedOn"
            :cpDeadline="CPDeadline"
            :docsApproved="DocApprovedStatus"
            :docsReceived="DocReceivedStatus"
            :paymentType="PaymentType"
            :ymm="VehicleYear + ' ' + VehicleMake + ' ' + VehicleModel"
          ></RegistrationItem>
          <v-divider v-if="idx < inProcessRegistrations.length - 1" :key="RegistrationID" class="my-4"></v-divider>
        </template>
      </v-list>

      <!-- <v-divider></v-divider> -->

      <!-- <div class="mt-15 text-h5">Completed Transactions ({{ completeRegistrations.length }})</div>
      <v-list two-line class="mt-5" v-if="completeRegistrations">
        <template
          v-for="({
            Status,
            DateCreated,
            VehicleYear,
            VehicleMake,
            VehicleModel,
            RegistrationID,
            RegistrationAmount,
            TotalReceived,
            ClientTrackingNumber,
            LastUpdatedCostBreakdown,
            LastUpdatedPaymentMethod,
            CPRequestSubmittedOn,
            DocApprovedStatus,
            DocReceivedStatus,
            PaymentType,
          },
          idx) in completeRegistrations"
        >
          <RegistrationItem
            :key="idx"
            :status="Status"
            :registrationAmount="RegistrationAmount"
            :costBreakdownDate="LastUpdatedCostBreakdown"
            :paymentMethodDate="LastUpdatedPaymentMethod"
            :clientTrackingNumber="ClientTrackingNumber"
            :date-created="DateCreated"
            :regId="RegistrationID"
            :totalReceived="TotalReceived"
            :cpRequestSubmittedOn="CPRequestSubmittedOn"
            :docsApproved="DocApprovedStatus"
            :docsReceived="DocReceivedStatus"
            :paymentType="PaymentType"
            :ymm="VehicleYear + ' ' + VehicleMake + ' ' + VehicleModel"
          ></RegistrationItem>
          <v-divider v-if="(idx < completeRegistrations.length - 1) && idx > 0" :key="RegistrationID" class="my-4"></v-divider>
        </template>
      </v-list> -->
    </template>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  components: {
    //LinkButton: () => import ('./components/LinkButton.vue'),
    RegistrationItem: () => import('./components/RegistrationItem.vue'),
    NoRegistrationsMsg: () => import('./components/NoRegistrationsMsg.vue'),
    CreateRegistrationModal: () => import('./components/CreateRegistrationModal.vue'),
  },
  created() {
    this.$store
      .dispatch('dashboard/getVehicles')
      .then(() => {
        // console.log('getVehicles is done');
      })
      .catch(err => {
        console.log('Dashboard::view getVehicles error', err);
      });
  },
  data: () => ({
    loading: false,
    transactionCompleteStatus: 'Transaction Completed',
  }),
  computed: {
    vehicles() {
      return this.$store.state.dashboard.vehicles;
    },
    inProcessRegistrations() {
      if(this.$store.state.dashboard.vehicles.length){
        return this.$store.state.dashboard.vehicles.filter(vehicle => vehicle.Status !== this.transactionCompleteStatus);
      } else {
        return {};
      }
    },
    completeRegistrations() {
      if(this.$store.state.dashboard.vehicles.length){
        return this.$store.state.dashboard.vehicles.filter(vehicle => vehicle.Status === this.transactionCompleteStatus);
      } else {
        return {};
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.error-msg {
  background: #f9c7c7;
  padding: 15px 10px;
  color: brown;
  border: none;
  margin-bottom: 20px;
}
</style>
