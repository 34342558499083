var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.dashboard.loading)?[_c('v-row',{staticStyle:{"padding":"80px 0"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"15"}},[_c('v-progress-circular',{attrs:{"size":70,"width":4,"color":"gray","indeterminate":""}})],1)],1)]:_vm._e(),_c('CreateRegistrationModal'),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"15"}},[_c('iframe',{attrs:{"src":"https://player.vimeo.com/video/543795257","width":"640","height":"360","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})])],1),(_vm.$store.state.dashboard.errorMessage)?_c('div',{staticClass:"error-msg"},[_vm._v("Error: "+_vm._s(_vm.$store.state.dashboard.errorMessage))]):_vm._e(),_c('NoRegistrationsMsg',{attrs:{"show-msg":!_vm.$store.state.dashboard.loading && !_vm.vehicles.length}}),(_vm.vehicles.length)?[_c('div',{staticClass:"mt-15 text-h5"},[_vm._v("Registrations In Process ("+_vm._s(_vm.inProcessRegistrations.length)+")")]),(_vm.inProcessRegistrations)?_c('v-list',{staticClass:"mt-4",attrs:{"two-line":""}},[_vm._l((_vm.inProcessRegistrations),function(ref,idx){
          var Status = ref.Status;
          var DateCreated = ref.DateCreated;
          var VehicleYear = ref.VehicleYear;
          var VehicleMake = ref.VehicleMake;
          var VehicleModel = ref.VehicleModel;
          var RegistrationID = ref.RegistrationID;
          var RegistrationAmount = ref.RegistrationAmount;
          var TotalReceived = ref.TotalReceived;
          var ClientTrackingNumber = ref.ClientTrackingNumber;
          var LastUpdatedCostBreakdown = ref.LastUpdatedCostBreakdown;
          var LastUpdatedPaymentMethod = ref.LastUpdatedPaymentMethod;
          var CPDeadline = ref.CPDeadline;
          var CPRequestSubmittedOn = ref.CPRequestSubmittedOn;
          var DocApprovedStatus = ref.DocApprovedStatus;
          var DocReceivedStatus = ref.DocReceivedStatus;
          var PaymentType = ref.PaymentType;
return [_c('RegistrationItem',{key:idx,attrs:{"status":Status,"registrationAmount":RegistrationAmount,"costBreakdownDate":LastUpdatedCostBreakdown,"paymentMethodDate":LastUpdatedPaymentMethod,"clientTrackingNumber":ClientTrackingNumber,"date-created":DateCreated,"regId":RegistrationID,"totalReceived":TotalReceived,"cpRequestSubmittedOn":CPRequestSubmittedOn,"cpDeadline":CPDeadline,"docsApproved":DocApprovedStatus,"docsReceived":DocReceivedStatus,"paymentType":PaymentType,"ymm":VehicleYear + ' ' + VehicleMake + ' ' + VehicleModel}}),(idx < _vm.inProcessRegistrations.length - 1)?_c('v-divider',{key:RegistrationID,staticClass:"my-4"}):_vm._e()]})],2):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }